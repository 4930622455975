<template>
  <v-app>
    <v-container fluid>
      <v-tabs v-model="xSelectedTab" class="">
        <v-tab
          v-for="(myClass, myClassesIndex) in myClasses"
          :key="'myClass' + myClassesIndex"
        >
          {{ myClass.STUDENT_GROUP_NAME }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="xSelectedTab" style="background-color: white">
        <v-tab-item
          v-for="(myClass, myClassesIndex) in myClasses"
          :key="'myClass' + myClassesIndex"
        >
          <ClassStudentsIrzConfirmation
            :zSchool="userData.school"
            :zClassGroup="myClass"
          ></ClassStudentsIrzConfirmation>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ClassStudentsIrzConfirmation from "@/sms/manager/ClassStudentsIrzConfirmation.vue";
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    ClassStudentsIrzConfirmation,
  },
  data: () => ({
    xSelectedTab: 0,
    myClasses: null,
  }),
  props: {
    teacherId: {
      type: Number,
    },
  },
  computed: {
    ...mapState(["userData", "currentUser"]),
    ...sync("*"),
  },
  created() {
    var xx =
      this.teacherId == undefined ? this.userData.PERSON_ID : this.teacherId;
    fb.db
      .collectionGroup("programs")
      .where("TEACHER_ID", "==", xx)
      .get()
      .then((docs) => {
        this.myClasses = [];
        docs.forEach((doc) => {
          let cg = doc.data();
          cg.id = doc.id;
          cg.ref = doc.ref;
          if (!cg.deleted) {
            if (!cg.ref.path.includes("/departments/")) {
              var found = this.myClasses.find((cc) => cc.id == cg.id);
              if (!found) this.myClasses.push(cg);
            }
          }
        });
      });
  },
};
</script>
